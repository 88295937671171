/**
 * List of roles and role definitions
 *
 * Shared between the frontend and the backend (shredder).
 *
 * Frontend: libs/fe-core/src/roles.ts
 * Backend: src/auth/roles.ts
 */

/**
 * Clients with exclusive features
 */
export const ROLE_CLIENTS = {
  criticalReflex: "client-critical-reflex",
  demo: "client-demo",
  exertisZtorm: "client-exertis-ztorm",
  /** Focus Entertainment and Dotemu */
  focus: "focus",
  haveli: "client-haveli",
  nocturne: "client-nocturne",
  playtonic: "client-playtonic",
  pqube: "client-pqube",
  starbreeze: "client-starbreeze",
} as const;

/**
 * Role list
 *
 * Prefer using `hasAnyRole` instead of checking the roles directly.
 */
export const ROLE_LIST = [
  // Full access
  "admin",
  // Any of GDCo paid clients
  "client",
  ...Object.values(ROLE_CLIENTS),
  // Access to GDCo Pro
  "pro",
] as const;
export type Role = (typeof ROLE_LIST)[number];

/** Role definitions */
export const ROLE_DEFS = {
  /** Full access */
  admin: ["admin"],
  /** All clients have exclusive features */
  anyClient: ["client", ...Object.values(ROLE_CLIENTS)],
  focus: [ROLE_CLIENTS.focus],
  clientCriticalReflex: [ROLE_CLIENTS.criticalReflex],
  clientDemo: [ROLE_CLIENTS.demo],
  clientExertisZtorm: [ROLE_CLIENTS.exertisZtorm],
  clientHaveli: [ROLE_CLIENTS.haveli],
  clientNocturne: [ROLE_CLIENTS.nocturne],
  clientPlaytonic: [ROLE_CLIENTS.playtonic],
  clientPQube: [ROLE_CLIENTS.pqube],
  clientStarbreeze: [ROLE_CLIENTS.starbreeze],
  /** GDCo Pro access */
  pro: ["pro"],
} satisfies Record<string, Role[]>;

/** Role parameters used by {@link featureRoles} */
export const roleParams = {
  allSignedInUsers: [null, undefined],
  everyone: [null, { allowSignedOut: true }],
  admin: [["admin"], undefined],
  anyClient: [ROLE_DEFS.anyClient, undefined],
  focus: [ROLE_DEFS.focus, undefined],
  clientCriticalReflex: [ROLE_DEFS.clientCriticalReflex, undefined],
  clientDemo: [ROLE_DEFS.clientDemo, undefined],
  clientExertisZtorm: [ROLE_DEFS.clientExertisZtorm, undefined],
  clientHaveli: [ROLE_DEFS.clientHaveli, undefined],
  clientNocturne: [ROLE_DEFS.clientNocturne, undefined],
  clientPlaytonic: [ROLE_DEFS.clientPlaytonic, undefined],
  clientPQube: [ROLE_DEFS.clientPQube, undefined],
  clientStarbreeze: [ROLE_DEFS.clientStarbreeze, undefined],
  pro: [["pro"], undefined],
} satisfies Record<string, RoleParams>;

/**
 * Roles for site features
 *
 * @example user.hasAnyRole(...featureRoles.affinityExplorer)
 */
export const featureRoles = {
  // All clients

  affinityExplorer: roleParams.anyClient,
  countryExplorer: roleParams.anyClient,
  detailsCountryOwnership: roleParams.anyClient,
  hypeConversionTagRanking: roleParams.anyClient,
  hypeConversionSignificantPostRelease: roleParams.anyClient,
  revenueAndSales: roleParams.anyClient,
  salesExplorer: roleParams.anyClient,
  trendingTagRanking: roleParams.anyClient,
  wishlists: roleParams.anyClient,

  // Client: Focus

  hypeAnnotator: roleParams.focus,
  studiosToWatch: roleParams.focus,

  // Client: Haveli

  companyHitList: roleParams.clientHaveli,

  // Client: Nocturne

  nocturne: roleParams.clientNocturne,

  // Trending pages (e.g. /trending/critical-reflex)
  // Use dash instead of camelCase for the key to match the URL.
  // Keep the main trending page as the first item.
  trending: {
    games: roleParams.clientDemo,
    "critical-reflex": roleParams.clientCriticalReflex,
    all: roleParams.focus,
    focus: roleParams.focus,
    haveli: roleParams.clientHaveli,
    nocturne: roleParams.clientNocturne,
    playtonic: roleParams.clientPlaytonic,
    pqube: roleParams.clientPQube,
  },

  // Pages

  browseGames: roleParams.allSignedInUsers,
  ccuRankings: roleParams.allSignedInUsers,
  compare: roleParams.allSignedInUsers,
  favoriteApps: roleParams.allSignedInUsers,
  followerVelocity: roleParams.allSignedInUsers,
  hypeChart: roleParams.allSignedInUsers,
  manualCharts: roleParams.allSignedInUsers,
  mostReviewed: roleParams.allSignedInUsers,
  postRelease: roleParams.allSignedInUsers,
  preReleaseTagRanking: roleParams.allSignedInUsers,
  publisherDeveloper: roleParams.allSignedInUsers,
  releaseHeatmap: roleParams.allSignedInUsers,
  revenuePredictor: roleParams.allSignedInUsers,
  saleEvents: roleParams.allSignedInUsers,
  tagExplorer: roleParams.allSignedInUsers,
  topFollowers: roleParams.allSignedInUsers,
  topWishlists: roleParams.allSignedInUsers,
  wishlistStats: roleParams.allSignedInUsers,

  manualChartAppleArcade: roleParams.allSignedInUsers,
  manualChartEpic: roleParams.allSignedInUsers,
  manualChartFortnite: roleParams.allSignedInUsers,
  manualChartMeta: roleParams.allSignedInUsers,
  manualChartSwitch: roleParams.allSignedInUsers,
  manualChartPlaystation: roleParams.allSignedInUsers,
  manualChartXbox: roleParams.allSignedInUsers,

  // Public pages

  changelog: roleParams.everyone,
  /** Ebook links are shown only for signed in users */
  ebooks: roleParams.everyone,
  pitchdecks: roleParams.everyone,

  // Table columns

  columnSelfPublished: roleParams.anyClient,
  columnsEarlyAccess: roleParams.admin,
  columnHypePubDev: roleParams.anyClient,
} satisfies Record<string, RoleParams | Record<string, RoleParams>>;

export type RoleParams = [
  Parameters<HasAnyRoleFn>[1],
  Parameters<HasAnyRoleFn>[2],
];

export function isRole(role: unknown): role is Role {
  return ROLE_LIST.includes(role as Role);
}

export function isRoleArray(roles: unknown): roles is Role[] {
  if (!Array.isArray(roles)) return false;
  return roles.every((role) => isRole(role));
}

/**
 * Check whether the user is signed in and has any of the given roles
 *
 * If the user has the `admin` role, they are considered to have all roles, unless `opts.skipAdmin` is true.
 *
 * @param user The user to check
 * @param roles The roles to check
 *              - If null, always returns true
 *             - If empty array, returns false (for admin, depends on `opts.skipAdmin`)
 * @param opts Options
 */
export type HasAnyRoleFn = (
  user: {
    roles: Role[];
    signedIn: boolean;
    fakeRoles?: Role[];
  },
  roles: Role[] | null,
  opts?: {
    /**
     * Whether to ignore the `admin` role
     *
     * @default false
     */
    ignoreAdmin?: boolean;
    /**
     * Whether to allow access when the user is signed out
     *
     * @default false
     */
    allowSignedOut?: boolean;
    /**
     * Whether to ignore fake roles, applies only to admins
     *
     * @default false
     */
    ignoreFakeRoles?: boolean;
  },
) => boolean;
